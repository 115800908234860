import './sprite';
import './vendors/transition';
import './vendors/collapse';
import './vendors/tab';
import './vendors/modal';
import './vendors/dropdown';
import './vendors/jquery.validate.min';
import './vendors/jquery.maskedinput.min';
import marquee from 'jquery.marquee';

function setRem(width) {
  if (width > 1279 && width < 1921) {
    let curSize = width / 1280 * 10;
    $('html').css('font-size', curSize + 'px');
  }
  if (width > 1920) {
    $('html').css('font-size', '15px');
  }
}


$(document).ready(() => {

  let inputs = $('#reg-form input:text'), some = [], firstReg = false;

  const wWidth =  $(window).width();
  setRem(wWidth);

  const params = window
    .location
    .search
    .replace('?','')
    .split('&')
    .reduce(
      function(p,e){
        let a = e.split('=');
        p[ decodeURIComponent(a[0])] = decodeURIComponent(a[1]);
        return p;
      },
      {}
    );

  $('.js-open-menu').on('click', () => {
    $('.js-main-content').toggleClass('menu-open');
    $('.header').toggleClass('menu-open');
    $('body').toggleClass('isMenuOpen');
    $('.js-open-menu').toggleClass('is-active');
  });

  $('.js-nav-link').on('click', function (e) {
    e.preventDefault();
    $('body').removeClass('isMenuOpen');
    const curLink = $(this).attr('href');
    let scroll = $(curLink).offset();

    $('html,body').stop().animate({
      scrollTop: scroll.top - 50
    }, 500);

    $('.js-main-content').removeClass('menu-open');
    $('.header').removeClass('menu-open');
    $('.js-open-menu').removeClass('is-active');
    $('#navbarNav').collapse('hide');
  });

  /*** How popup ***/
  $('.js-how-open-popup').on('click', () => {
    showHowPopup();
  });
  $('.js-how-close-popup').on('click', () => {
    hideHowPopup()
  });

  function showHowPopup() {
    $('.howPopup').addClass('show');
    $('body').addClass('isMenuOpen');
    document.getElementById('local-video').play();
  }

  function hideHowPopup() {
    $('.howPopup').removeClass('show');
    $('body').removeClass('isMenuOpen');
    document.getElementById('local-video').pause();
  }

  /*** Marquee ***/
  $('.m-animate.forwards').marquee({
    //speed in milliseconds of the marquee
    duration: 26000,
    //gap in pixels between the tickers
    gap: 0,
    //time in milliseconds before the marquee will start animating
    delayBeforeStart: 0,
    //'left' or 'right'
    direction: 'left',
    //true or false - should the marquee be duplicated to show an effect of continues flow
    duplicated: true,
    startVisible: true
  });
  $('.m-animate.backwards').marquee({
    //speed in milliseconds of the marquee
    duration: 38000,
    //gap in pixels between the tickers
    gap: 0,
    //time in milliseconds before the marquee will start animating
    delayBeforeStart: 100,
    //'left' or 'right'
    direction: 'right',
    //true or false - should the marquee be duplicated to show an effect of continues flow
    duplicated: true,
    startVisible: true
  });

  inputs.on('change',() => {
    some[0] = '';
    inputs.each(function() {
      some[0] += $(this).val();
    });
    firstReg = true;
  });


  $('.js-faq').on('click', function () {
    const questionId = $(this).attr('href').replace('#', '')
    console.log(questionId)
    $('[data-question]').addClass('hidden')
    $(`[data-question=${questionId}]`).removeClass('hidden')

    let $currentQuestion = $(this);
    let $card = $(this).parents('.card');
    let title;
    let text;
    $('.card-collapse').collapse('hide');
    $($(this).attr('href')).collapse('show');

    // if ($(window).width() > 767) {
    //   if (!isFaqStart) {
    //     if (!$currentQuestion.hasClass('collapsed')) {
    //       title = $card.find('[data-title]').html();
    //       text = $card.find('[data-answer]').html();
    //     } else {
    //       title = 'Кто может принимать участие в&nbsp;отборе?';
    //       text = '.</p>';
    //     }
    //   }
    // } else {
    //   let $currentQuestion = $(this);
    //   let isNotCollapsed = false;
    //   $currentQuestion.hasClass('collapsed') ? isNotCollapsed = true : isNotCollapsed = false;
    //   $('.faq-answers').find('.js-faq').fadeTo(100, 0);
    //   setTimeout(() => {
    //     $('.faq-answers').find('.js-faq').not($currentQuestion).removeClass('question').addClass('card-header').fadeTo(200, 1);
    //     if (!$currentQuestion.hasClass('collapsed')) {
    //       //$currentQuestion.fadeTo(200, 0);
    //     }
    //   }, 100);
    //   setTimeout(() => {
    //     if (!$currentQuestion.hasClass('collapsed')) {
    //       $currentQuestion.removeClass('card-header').addClass('question').fadeTo(200, 1);
    //     } else {
    //       $currentQuestion.removeClass('question').addClass('card-header').fadeTo(200, 1);
    //     }
    //   }, 250);
    // }
  });

  $('.js-journey-advanced').on('click', function () {
    $('.js-journey-advanced').removeClass('active')
    $(this).addClass('active')
  });

  $('.js-journey-basic').on('click', function () {
    $('.js-journey-basic').removeClass('active')
    $(this).addClass('active')
  });

});
